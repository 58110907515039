import Home from './views/Home';
import Dashboard from './views/Dashboard';
import RecordsReadUpdate from './views/RecordsReadUpdate';
import RecordsCreate from './views/RecordsCreate';
import RecordsDelete from './views/RecordsDelete';
import Overview from "./views/Overview";
import About from "./views/About";
import Faq from "./views/FAQ";
import EmergencyNip from "./views/EmergencyNip";
import DeleteAccount from "./views/AccountDelete";
import TermsPrivacy from './views/TermsPrivacy';
import TermsService from './views/TermsService';
// import Test from './views/test/RecordsTest';

import CookieBanner from './views/PrivacyPolicy'; 

import { Route, Routes, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div >

      <header >
        <nav class="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
          <div class="container-fluid">
            <Link class="navbar-brand" to="home">3vidence </Link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarColor01">
              <ul class="navbar-nav me-auto">
                {/* <li class="nav-item">
                  <Link class="nav-link text-white" to="home"><strong>{t('head.home')}</strong></Link >
                </li> */}
                <li class="nav-item">
                  <Link class="nav-link text-white" to="app"><strong>{t('head.myrecords')}</strong></Link >
                </li>
              </ul>
              <div class="d-flex ms-auto">
                <button class="btn btn-light btn-sm  me-2" onClick={() => changeLanguage('en')}>EN</button>
                <button class="btn btn-light btn- me-2" onClick={() => changeLanguage('es')}>ES</button>
              </div>
            </div>
          </div>
        </nav>

      </header>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home/overview" element={<Overview />} />
        <Route path="/home/about" element={<About />} />
        <Route path="/home/faq" element={<Faq />} />
        <Route path="/app" element={<Dashboard />} />
        <Route path="/app/dashboard" element={<Dashboard />} />
        <Route path="/app/records" element={<RecordsReadUpdate />} />
        <Route path="/app/create" element={<RecordsCreate />} />
        <Route path="/app/delete" element={<RecordsDelete />} />
        <Route path="/emergency/:otp"
          loader={({ params }) => {
            console.log(params.otp);
          }}
          action={({ params }) => { }}
          element={<EmergencyNip />}
        />
        <Route path="/app/delete/account" element={<DeleteAccount />} />
        <Route path="/privacypolicy" element={<TermsPrivacy />} />
        <Route path="/termsofservice" element={<TermsService />} />
        {/* <Route path="/app/test" element={<Test />} /> */}
      </Routes>

      <CookieBanner />

    </div>
  );
}

export default App;
