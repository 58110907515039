import { useTranslation } from 'react-i18next';


export default function TermsService() {

  const { t } = useTranslation();

  return (
    <div>
      <div className="container">
        <br />
        <h1>{t('termsOfService.title')}</h1>
        <p>{t('termsOfService.lastUpdate')}</p>
        <br />

        <section>
          <strong>{t('termsOfService.acceptanceOfTerms.title')}</strong>
          <p>{t('termsOfService.acceptanceOfTerms.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.serviceDescription.title')}</strong>
          <p>{t('termsOfService.serviceDescription.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.userResponsibility.title')}</strong> <br />

          <em>{t('termsOfService.userResponsibility.accuracyOfInformation.title')}</em>
          <p>{t('termsOfService.userResponsibility.accuracyOfInformation.content')}</p>

          <em>{t('termsOfService.userResponsibility.accessToInformation.title')}</em>
          <p>{t('termsOfService.userResponsibility.accessToInformation.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.limitationOfLiability.title')}</strong> <br />

          <em>{t('termsOfService.limitationOfLiability.noDataAccess.title')}</em>
          <p>{t('termsOfService.limitationOfLiability.noDataAccess.content')}</p>

          <em>{t('termsOfService.limitationOfLiability.exclusionOfLiability.title')}</em>
          <p>{t('termsOfService.limitationOfLiability.exclusionOfLiability.content')}</p>

          <em>{t('termsOfService.limitationOfLiability.userResponsibility.title')}</em>
          <p>{t('termsOfService.limitationOfLiability.userResponsibility.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.informationSecurity.title')}</strong> <br />

          <em>{t('termsOfService.informationSecurity.encryptionAndProtection.title')}</em>
          <p>{t('termsOfService.informationSecurity.encryptionAndProtection.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.thirdPartyServiceUse.title')}</strong> <br />

          <em>{t('termsOfService.thirdPartyServiceUse.emergencyServiceAccess.title')}</em>
          <p>{t('termsOfService.thirdPartyServiceUse.emergencyServiceAccess.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.termsModifications.title')}</strong>
          <p>{t('termsOfService.termsModifications.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.applicableLaw.title')}</strong>
          <p>{t('termsOfService.applicableLaw.content')}</p>
        </section>
        <br />

        <section>
          <strong>{t('termsOfService.contact.title')}</strong>
          <p>{t('termsOfService.contact.content')}</p>
        </section>
        <br />
      </div>
    </div>
  );
}

