import { useTranslation } from 'react-i18next';


export default function TermsPrivacy() {

  const { t } = useTranslation();

  return (
    <div>
      <div className="container ">

            <br />
            <h1>{t('privacyPolicy.title')}</h1>
            <p>{t('privacyPolicy.lastUpdate')}</p>

            <section>
              <strong>{t('privacyPolicy.introduction.title')}</strong>
              <p>{t('privacyPolicy.introduction.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.informationWeCollect.title')}</strong> <br /> 

              <em>{t('privacyPolicy.informationWeCollect.personalInformation.title')}</em>
              <p>{t('privacyPolicy.informationWeCollect.personalInformation.content')}</p>

              <em>{t('privacyPolicy.informationWeCollect.technicalInformation.title')}</em>
              <p>{t('privacyPolicy.informationWeCollect.technicalInformation.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.useOfInformation.title')}</strong> <br />

              <em>{t('privacyPolicy.useOfInformation.personalInformation.title')}</em>
              <p>{t('privacyPolicy.useOfInformation.personalInformation.content')}</p>

              <em>{t('privacyPolicy.useOfInformation.technicalInformation.title')}</em>
              <p>{t('privacyPolicy.useOfInformation.technicalInformation.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.informationProtection.title')}</strong> <br />

              <em>{t('privacyPolicy.informationProtection.encryption.title')}</em>
              <p>{t('privacyPolicy.informationProtection.encryption.content')}</p>

              <em>{t('privacyPolicy.informationProtection.restrictedAccess.title')}</em>
              <p>{t('privacyPolicy.informationProtection.restrictedAccess.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.informationRetention.title')}</strong> <br />

              <em>{t('privacyPolicy.informationRetention.retentionPeriod.title')}</em>
              <p>{t('privacyPolicy.informationRetention.retentionPeriod.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.userRights.title')}</strong> <br />

              <em>{t('privacyPolicy.userRights.accessCorrection.title')}</em>
              <p>{t('privacyPolicy.userRights.accessCorrection.content')}</p>

              <em>{t('privacyPolicy.userRights.dataDeletion.title')}</em>
              <p>{t('privacyPolicy.userRights.dataDeletion.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.informationSharing.title')}</strong> <br />

              <em>{t('privacyPolicy.informationSharing.emergencyServices.title')}</em>
              <p>{t('privacyPolicy.informationSharing.emergencyServices.content')}</p>

              <em>{t('privacyPolicy.informationSharing.legalCompliance.title')}</em>
              <p>{t('privacyPolicy.informationSharing.legalCompliance.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.security.title')}</strong> <br />

              <em>{t('privacyPolicy.security.securityMeasures.title')}</em>
              <p>{t('privacyPolicy.security.securityMeasures.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.policyChanges.title')}</strong> <br />
              <p>{t('privacyPolicy.policyChanges.content')}</p>
            </section> <br />

            <section>
              <strong>{t('privacyPolicy.contact.title')}</strong> <br />
              <p>{t('privacyPolicy.contact.content')}</p>
            </section> <br />


      </div>
    </div>
  );
}

